<template>
  <div>
    <headers />
    <div class="mb-30">
      <div class="relative" data-projection-id="150" style="opacity: 1">
        <div data-testid="hero-image-bg" class="top-0 left-0 z-[-1] h-full w-full relative md:absolute">
          <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b1.png')" alt="" />
        </div>
        <div
          class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] relative md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:h-[650px]"
        >
          <div
            class="col-end-flex flex flex-col col-start-2 col-span-7 md:col-start-2 md:col-span-15 lg:col-start-2 h-full justify-center z-1"
          >
            <div class="relative my-8 md:my-5 flex flex-col items-start text-left content-start justify-start">
              <h1 class="text-2xl md:text-4xl text-iblack-700 dark:text-iblack-300 max-w-[500px]">Insights</h1>
              <p class="text-lg text-iblack-600 dark:text-iblack-400 mt-2.5 md:mt-2.5 max-w-[500px]">
                The latest on ZEN CAPITAL Impact’s<br class="hidden md:inline" />
                collaborative philanthropy initiatives.
              </p>
            </div>
          </div>
        </div>
        <div class="main-grid">
          <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block md:hidden">
            <div
              role="separator"
              aria-orientation="horizontal"
              class="w-full h-full block"
              data-projection-id="151"
              style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: hidden">
      <div class="main-grid mb-30">
        <a
          draggable="false"
          target="_self"
          class="main-grid--in-grid transition-opacity duration-500 hover:opacity-80 focus:opacity-80"
          href="/impact"
          ><div
            class="relative transition-opacity duration-300 overflow-hidden"
            data-projection-id="215"
            style="opacity: 1; transform: none"
          >
            <div
              data-testid="tout-image-bg"
              class="col-start-2 col-span-23 rounded md:h-full w-full sm:relative md:absolute top-0 left-0 z-[-1] overflow-hidden"
            >
              <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b2.png')" alt="" />
            </div>
            <div
              class="md:h-[400px] grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:h-[400px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] col-start-2 col-span-full relative"
            >
              <div
                class="col-end-flex flex flex-col z-1 col-start-1 col-span-15 md:col-start-3 lg:col-start-3 justify-center"
              >
                <div class="mt-[30px] md:my-0" data-projection-id="216" style="opacity: 1; transform: none">
                  <h1 class="text-2xl text-iblack-700 dark:text-iblack-300 md:dark:text-iblack-700">
                    Stronger Democracy Award Grants<br class="hidden md:inline" />
                    $22 Million to Three Organizations
                  </h1>
                  <p class="text-lg text-iblack-600 dark:text-iblack-600 md:dark:text-iblack-600 mt-2.5 max-w-[500px]">
                    The recipients of the Stronger Democracy Award are working<br class="hidden md:inline" />
                    to enhance transparency and accountability in local politics,<br class="hidden md:inline" />
                    build a robust multiparty democracy that represents all citizens,<br class="hidden md:inline" />
                    and cultivate a more engaged and informed voter base.
                  </p>
                </div>
              </div>
            </div>
          </div></a
        >
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="154">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="155"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b3.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="156"
                            style="opacity: 1; transform: none"
                          >
                            New $10 Million Award Launched to Improve Maternal and Infant Health Outcomes Around the
                            World
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="157"
                            style="opacity: 1; transform: none"
                          >
                            The Maternal &amp; Infant Health Award is a $10 million grant competition to help improve
                            maternal and infant health outcomes across the globe.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="158">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="159"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b4.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="160"
                            style="opacity: 1; transform: none"
                          >
                            Five Outstanding Organizations Working To Save Democracy in the United States
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="161"
                            style="opacity: 1; transform: none"
                          >
                            The Stronger Democracy Award is a $22M grant competition to help improve political
                            representation and increase participation in the United States’ democratic process. We’re
                            thrilled to introduce the five finalists.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="162">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        data-projection-id="163"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b5.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="164"
                            style="opacity: 1; transform: none"
                          >
                            Harnessing the Untapped Power of Female Workers to Enhance Climate Resiliency
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="165"
                            style="opacity: 1; transform: none"
                          >
                            To celebrate the launch of the ZEN CAPITAL Impact Climate Equity Co-Lab, we're highlighting the
                            three portfolio organizations helping women protect their lives and our planet through
                            climate-related employment.&nbsp;
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            data-projection-id="166"
            style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-2 relative">
                <div>
                  <section class="" data-projection-id="167">
                    <div
                      class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                      data-projection-id="168"
                      style="opacity: 1; transform: none"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b6.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-6">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                          data-projection-id="169"
                          style="opacity: 1; transform: none"
                        >
                          Finalists Selected for $22 Million Award to Strengthen Democracy in the United States
                        </h1>
                        <p class="text-iblack-600 mt-2.5" data-projection-id="170" style="opacity: 1; transform: none">
                          The Stronger Democracy Award is a $22M grant competition to help improve political
                          representation and increase participation in the United States’ democratic process.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-8 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="171">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="172"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b7.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="173"
                            style="opacity: 1; transform: none"
                          >
                            Empowering Women and Indigenous Leaders to Reclaim and Protect Their Land
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="174"
                            style="opacity: 1; transform: none"
                          >
                            To celebrate the launch of the ZEN CAPITAL Impact Climate Equity Co-Lab, we're highlighting the
                            three portfolio organizations supporting hyperlocal environmental leaders.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="175">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="176"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b8.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="177"
                            style="opacity: 1; transform: none"
                          >
                            How Sustainable Agriculture Can Lift Women and Their Families Out of Poverty
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="178"
                            style="opacity: 1; transform: none"
                          >
                            The three portfolio organizations lifting women and their families out of poverty by
                            improving sustainable farming practices.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-5 col-start-20 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="179">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="180"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b9.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="181"
                            style="opacity: 1; transform: none"
                          >
                            Strengthening Female-led Conservation to Combat Climate Change
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="182"
                            style="opacity: 1; transform: none"
                          >
                            Four portfolio organizations fighting climate change by strengthening female-led
                            conservation and land stewardship.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="183">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="184"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b10.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="185"
                            style="opacity: 1; transform: none"
                          >
                            ZEN CAPITAL Impact Launches the $50M Climate Equity Co-Lab
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="186"
                            style="opacity: 1; transform: none"
                          >
                            We're thrilled to announce the launch of a philanthropic fund supporting organizations at
                            the intersection of climate change, gender equity, and poverty.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-8 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="187">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="188"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b11.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="189"
                            style="opacity: 1; transform: none"
                          >
                            Celebrating World Refugee Day by Placing Power and Resources in the Hands of Refugees
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="190"
                            style="opacity: 1; transform: none"
                          >
                            ZEN CAPITAL Impact shares the story behind the Larsen Lam ZEN CAPITAL Impact Award and how it's
                            helping shift power and resources back into the hands of refugees.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="191">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="192"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b12.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="193"
                            style="opacity: 1; transform: none"
                          >
                            Building a Durable Future for Refugees with the Larsen Lam ZEN CAPITAL Impact Award
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="194"
                            style="opacity: 1; transform: none"
                          >
                            Meet the five finalists of the first ZEN CAPITAL Impact Award: a $12 million grant sponsored by
                            Chris Larsen and Lyna Lam, with support from the Sea Grape Foundation, to provide impactful
                            programs for refugees.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-5 col-start-20 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="195">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="196"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b13.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="197"
                            style="opacity: 1; transform: none"
                          >
                            New $12 Million Grant Competition Seeks to Create a Durable Future for Refugees
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="198"
                            style="opacity: 1; transform: none"
                          >
                            Larsen Lam ZEN CAPITAL Impact Award is first in a series of ZEN CAPITAL Impact Competitions managed by
                            Lever for Change.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="199">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="200"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b14.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="201"
                            style="opacity: 1; transform: none"
                          >
                            Larsen Lam ZEN CAPITAL Impact Award Grants Over $24 Million to Create Long-Term Transformational
                            Change for Refugees
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="202"
                            style="opacity: 1; transform: none"
                          >
                            The Resourcing Refugee Leadership Initiative is selected as the $10M award recipient by
                            sponsors Chris Larsen and Lyna Lam, while DREAMS for Refugees is selected as the $2M award
                            recipient by the Sea Grape Foundation.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-8 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="203">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="204"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b15.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="205"
                            style="opacity: 1; transform: none"
                          >
                            Resourcing Refugee Leadership Initiative selected by sponsors Chris Larsen and Lyna Lam as
                            the $10 million recipient of the Larsen Lam ZEN CAPITAL Impact Award
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="206"
                            style="opacity: 1; transform: none"
                          >
                            Meet the leaders of the Resourcing Refugee Leadership Initiative and discover how they will
                            use the award to provide impactful programs for refugees.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-5 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="207">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="208"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b16.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="209"
                            style="opacity: 1; transform: none"
                          >
                            Larsen Lam ZEN CAPITAL Impact Award Funds Four Finalist Organizations Creating a Better Future
                            for Refugees
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="210"
                            style="opacity: 1; transform: none"
                          >
                            Meet the inspiring individuals leading these four impactful organizations.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-5 col-start-20 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/impact"
                    ><section class="" data-projection-id="211">
                      <div
                        class="relative overflow-hidden rounded h-[216px] md:h-[140px] lg:h-[177px]"
                        data-projection-id="212"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/impactinsights/b17.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-6">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg"
                            data-projection-id="213"
                            style="opacity: 1; transform: none"
                          >
                            ZEN CAPITAL Impact and Lever for Change Announce a $10M Increase to the Stronger Democracy Award
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            data-projection-id="214"
                            style="opacity: 1; transform: none"
                          >
                            An anonymous donor in the ZEN CAPITAL Impact community contributes $10 million to the Stronger
                            Democracy Award, bringing the total funding to $22 million.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
